@import url("https://fonts.googleapis.com/css2?family=Lora:ital,wght@0,400;0,500;0,600;0,700;1,400;1,500;1,600;1,700&display=swap");
@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  html {
    font-family: Lora, serif;
  }

  h1 {
    @apply text-3xl font-semibold;
  }

  h3 {
    @apply text-4xl font-semibold;
  }

  h4 {
    @apply text-3xl font-normal;
  }

  a {
    @apply font-semibold no-underline;
  }
}

.paragraph {
  @apply text-lg font-normal text-black md:text-xl text-end;

  &--small {
    @extend .paragraph;
    @apply text-base self-start;
  }

  &--project-topic {
    @extend .paragraph;
    @apply text-base md:text-xl font-semibold;
  }

  &--project-title {
    @apply md:text-xl text-xs font-normal text-gray-500 group-hover:text-footer-orange;
  }

  &--contact {
    @extend .paragraph;
    @apply text-xs;
  }
}

.line {
  @apply w-16 border-b-2 border-black;
}

.socialIcons--linkedIn {
  @apply hover:opacity-50;
}
